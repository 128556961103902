import { Alert, Button, Col, Form, Input, Row, Space, Spin } from "antd";
import { Field } from "@/components/common/Form/Field";
import { isUniqueName } from "@/utils/validation";
import { checkOpenplayQuery, findOpenplayQueryByName } from "@/api/openplay-queries";
import { capitalize } from "@/utils";
import { OpenplaySQLEditor } from "@/components/OpenplayQueries/Details/OpenplaySQLEditor";
import { useRequest } from "ahooks";
import { useEffect } from "react";

const { TextArea } = Input;

type Props = {
  readOnly?: boolean;
};

export const OpenplayQueryFields = ({ readOnly }: Props) => {
  const { data: status, loading: refreshing, run: refresh, reset } = useRequest(checkOpenplayQuery, {
    manual: true,
  });

  const sql = Form.useWatch("sql");

  const canCheckQuery = !!sql?.trim();

  const handleCheckQuery = () => {
    return refresh(sql);
  };

  useEffect(() => {
    reset();
  }, [reset, sql]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field
            name="name"
            label="Name"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, message: "Please, enter a name for the query" },
              isUniqueName(findOpenplayQueryByName, "A query with entered name already exists in the system"),
            ]}
          >
            <Input placeholder="Name" />
          </Field>
        </Col>
        <Col span={12}>
          <Field name="description" label="Description">
            <TextArea placeholder="Description" rows={2} />
          </Field>
        </Col>
        <Col span={24}>
          <Form.Item label="SQL" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {status && (
                <Spin spinning={refreshing}>
                  <Alert
                    type={status.success ? "success" : "error"}
                    message={
                      status.success === true
                        ? status.count > 0
                          ? `Query is correct. ${status.count} row${status.count === 1 ? "" : "s"} found.`
                          : "Query is correct, but no rows found."
                        : `Query has an error: ${capitalize(status.reason)}. ` +
                          `Please review your SQL syntax.`
                    }
                  />
                </Spin>
              )}
              <Field
                name="sql"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, provide an SQL query",
                    transform: (value) => value?.trim(),
                  },
                  {
                    validator: async (_: any, value: string) => {
                      const status = await checkOpenplayQuery(value);
                      if (status.success === true) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(status.reason);
                      }
                    },
                    validateTrigger: ["onSubmit"],
                  },
                ]}
                validateTrigger={["onChange", "onSubmit"]}
              >
                <OpenplaySQLEditor placeholder="SELECT id FROM ..." readOnly={readOnly} />
              </Field>
              <Button size="small" loading={refreshing} onClick={handleCheckQuery} disabled={!canCheckQuery}>
                Check Query
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
