import { SaveAsTemplateButton } from "@/components/Templates/SaveAsTemplateButton";
import type { TabSource } from "@/utils";
import { TemplateType } from "@/utils";
import { SaveAsTemplateForm } from "@/components/Templates/SaveAsTemplateForm";
import { useBoolean } from "ahooks";
import { useCallback } from "react";
import { Form } from "antd";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";
import { useFieldPath } from "@/components/common/Form/hooks";

type Props = {
  disabled?: boolean;
  sourceObject: string;
  source: TabSource;
};

export const SaveAsColumnSetTemplate = ({ disabled = false, sourceObject, source }: Props) => {
  const form = Form.useFormInstance();

  const { getAbsolutePath } = useFieldPath();

  const [isFormVisible, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const handleClick = async () => {
    const columnsField = getAbsolutePath("columns");
    const columns = (form.getFieldValue(getAbsolutePath("columns")) ?? []) as SpreadsheetColumn[];
    const nestedFields = columns.flatMap((_, index) =>
      ["name", "key", "variable", "path", "width", "source", "dateFormat"].map((nestedField) => [
        ...columnsField,
        index,
        nestedField,
      ])
    );
    const fieldsToValidate = [columnsField, ...nestedFields];

    try {
      await form.validateFields(fieldsToValidate);
      openForm();
    } catch (e) {
      console.error(e);
    }
  };

  const getTemplateFields = useCallback(() => {
    return {
      type: TemplateType.SpreadsheetColumnSet,
      data: {
        sourceObject: sourceObject,
        source: source,
        columns: form.getFieldValue(getAbsolutePath("columns")),
      },
    };
  }, [form, getAbsolutePath, sourceObject]);

  return (
    <>
      <SaveAsTemplateButton
        onClick={handleClick}
        disabled={disabled}
        unavailable={false}
        unavailableTooltip={""}
        buttonType="text"
      />
      <SaveAsTemplateForm
        isVisible={isFormVisible}
        onClose={closeForm}
        getTemplateFields={getTemplateFields}
      />
    </>
  );
};
