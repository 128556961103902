import { Alert, Button, Col, Form, Input, Popconfirm, Row, Space } from "antd";
import { Field } from "@/components/common/Form/Field";
import { ColumnSetPicker } from "./ColumnSetPicker";
import { SpreadsheetColumnList } from "@/components/Spreadsheets/SpreadsheetColumnList";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { useBoolean } from "ahooks";
import { useFieldPath } from "@/components/common/Form/hooks";
import type { SpreadsheetColumnSet } from "@/api/spreadsheets/types";
import { useSpreadsheetFormContext } from "@/components/Spreadsheets/SpreadsheetForm/context";
import { useOpenplayQuery } from "@/api/openplay-queries/hooks";
import { TabSource } from "@/utils";
import { useMemo } from "react";

type Props = {
  sourceObject?: string;
  source?: TabSource;
};

const MissingSourceObjectWarning = () => {
  const { entity } = useSpreadsheetFormContext();

  if (entity === "email-alert") {
    return null;
  }

  return <Alert type="warning" message="Please, set up and select the query to be able to add columns" />;
};

export const ColumnSetForm = ({ sourceObject, source }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const { entity } = useSpreadsheetFormContext();

  const form = Form.useFormInstance();
  const id = Form.useWatch<string>(getAbsolutePath(["columnSet", "id"]));
  const openplayQueryId = Form.useWatch<string>(getAbsolutePath(["openplayQueryId"]));

  const { data: openplayQuery } = useOpenplayQuery(openplayQueryId);

  const canAddColumns = useMemo(() => {
    if (entity === "email-alert") {
      return !!source;
    }
    if (source === TabSource.OpenPlayQuery) {
      return !!openplayQueryId;
    }
    if (source === TabSource.Salesforce || source === TabSource.OpenPlay) {
      return !!sourceObject;
    }
    return false;
  }, [entity, openplayQueryId, source, sourceObject]);

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean();

  const selected = !!id;
  const editable = selected && !editing;

  const handleCreate = () => {
    form.setFieldValue(getAbsolutePath("columnSet"), { columns: [] });
    enableEditing();
  };

  const handleReset = () => {
    form.resetFields([getAbsolutePath("columnSet"), getAbsolutePath("source")]);
    disableEditing();
  };

  const handleEdit = () => {
    enableEditing();
  };

  const handleDuplicate = () => {
    form.setFieldValue(getAbsolutePath(["columnSet", "id"]), undefined);
    enableEditing();
  };

  const handleSelect = (_, entry: SpreadsheetColumnSet) => {
    form.setFieldValue(getAbsolutePath("columnSet"), entry);
  };

  return (
    <FieldGroup prefix="columnSet">
      <Row gutter={[16, 16]}>
        {selected && (
          <Col span={24}>
            <Alert
              showIcon
              message={
                <>
                  Editing existing column set <strong>may impact</strong> other reports or email alerts that
                  reference it. If you want to use it as a starting point, consider using{" "}
                  <strong>Duplicate & Edit</strong>.
                </>
              }
            />
          </Col>
        )}
        {canAddColumns && (
          <Col span={24}>
            <Space size={8}>
              <Field name="id">
                <ColumnSetPicker
                  filters={source === TabSource.OpenPlayQuery ? { source } : { sourceObject, source }}
                  disabled={editing}
                  onChange={handleSelect}
                />
              </Field>
              {editable && (
                <>
                  <Popconfirm
                    title="Editing existing column set may impact other reports or email alerts that reference it"
                    okText="Edit Anyway"
                    onConfirm={handleEdit}
                    overlayStyle={{ maxWidth: 300 }}
                  >
                    <Button size="small">Edit</Button>
                  </Popconfirm>
                  <Button size="small" onClick={handleDuplicate}>
                    Duplicate & Edit
                  </Button>
                </>
              )}
              {editing && (
                <Button size="small" onClick={handleReset}>
                  Reset
                </Button>
              )}
              {!editing && (
                <Button size="small" type="primary" onClick={handleCreate}>
                  {selected ? "Reset & Create New" : "Create"}
                </Button>
              )}
            </Space>
          </Col>
        )}
        {canAddColumns && (
          <Col span={12}>
            <Field
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  transform: (value) => value?.trim(),
                  message: "Field is mandatory",
                },
              ]}
              validateFirst
            >
              <Input placeholder="Column Set Name" disabled={!editing} />
            </Field>
          </Col>
        )}
        {!canAddColumns && (
          <Col span={24}>
            <MissingSourceObjectWarning />
          </Col>
        )}
        <Col span={24}>
          <SpreadsheetColumnList
            sourceObject={sourceObject}
            source={source}
            canAdd={canAddColumns}
            disabled={!editing}
            openplayQuery={openplayQuery}
          />
        </Col>
      </Row>
    </FieldGroup>
  );
};
