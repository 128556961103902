import cn from "classnames";
import CodeMirror from "@uiw/react-codemirror";
import { Form } from "antd";
import type { SQLNamespace } from "@codemirror/lang-sql";
import { PostgreSQL } from "@codemirror/lang-sql";
import "./SQLEditor.less";
import { langs } from "@uiw/codemirror-extensions-langs";
import { sublime } from "@uiw/codemirror-theme-sublime";
import { format } from "sql-formatter";

type Props = {
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  schema?: SQLNamespace;
};

export const SQLEditor = ({ placeholder, readOnly, onChange, value, schema }: Props) => {
  const { status } = Form.Item.useStatus();

  const handlePrettify = () => {
    onChange(format(value ?? "", { language: "postgresql", tabWidth: 2, keywordCase: "upper" }));
  };

  return (
    <div className={cn("sql-editor", status === "error" && "sql-editor--error")}>
      <button className="sql-editor__prettify" type="button" onClick={handlePrettify}>
        Prettify
      </button>
      <CodeMirror
        placeholder={placeholder}
        height="auto"
        value={value ?? ""}
        onChange={onChange}
        extensions={[
          langs.sql({
            dialect: PostgreSQL,
            schema,
          }),
        ]}
        basicSetup={{
          highlightActiveLineGutter: false,
        }}
        readOnly={readOnly}
        theme={sublime}
      />
    </div>
  );
};
