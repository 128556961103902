import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import type { SelectProps } from "antd";
import { Form, Select } from "antd";
import { getEmptyArray } from "@/utils/empty";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";

type Props = Pick<SelectProps, "value" | "onChange" | "disabled">;

export const OpenplayQueryPicker = ({ value, onChange, disabled }: Props) => {
  const {
    data: { data: entries },
    isLoading,
  } = useOpenplayQueries();
  const selectedQueries = Form.useWatch<{ id: string }[]>("openplayQueries") ?? getEmptyArray();
  const selectedIds = selectedQueries.filter((query) => !!query.id).map(({ id }) => id);

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Query"
      loading={isLoading}
      allowClear={!disabled}
      disabled={isLoading || disabled}
      options={entries
        .filter((entry) => selectedIds.includes(entry.id))
        .map((entry) => ({ value: entry.id, label: entry.name }))}
      suffixIcon={<Icon component={DropdownArrow} />}
      notFoundContent="No queries available"
    />
  );
};
