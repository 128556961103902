import { Col, Divider, Form, Input, Row } from "antd";
import { Field } from "../../../../common/Form/Field";
import { Box } from "../../../../common/Box/Box";
import { useFieldPath } from "../../../../common/Form/hooks";
import { FormattingRuleList } from "@/components/Reports/Details/SpreadsheetTabs/FormattingRuleList/FormattingRuleList";
import { RowByField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/RowByField/RowByField";
import { TabSourceField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/TabSourceField";
import { TabSource } from "@/utils/tab-source";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import type { SalesforceQuery } from "@/api/reports/types";
import { QueryPickerField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/QueryPickerField";
import { ColumnSetForm } from "./ColumnSetForm/ColumnSetForm";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { HiddenField } from "../../../../common/HiddenField";
import { useSpreadsheetFormContext } from "@/components/Spreadsheets/SpreadsheetForm/context";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";
import { OpenplayQueryPicker } from "../../OpenplayQueryPicker";

const useSourceObject = () => {
  const { getAbsolutePath } = useFieldPath();
  const { entity } = useSpreadsheetFormContext();
  const reportQueries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const selectedQueryId = Form.useWatch<string>(getAbsolutePath("query")) ?? null;
  const sourceObjectName = Form.useWatch<string>(["sourceObject", "name"]) ?? null;

  if (entity === "on-demand-report") {
    return null;
  }

  if (entity === "email-alert") {
    return sourceObjectName;
  }

  return (selectedQueryId && reportQueries.find((query) => query.id === selectedQueryId)?.from) ?? null;
};

const OpenplayQueryPickerField = () => {
  const { getAbsolutePath } = useFieldPath();

  const columns = Form.useWatch<SpreadsheetColumn[]>(getAbsolutePath(["columnSet", "columns"])) ?? [];
  const isDisabled = columns.length > 0;

  return (
    <Field
      label="Openplay Query"
      name="openplayQueryId"
      validateFirst
      rules={[
        { required: true, message: "Please, select query" },
        (form) => ({
          validator: (_, value) => {
            const availableQueries: { id: string }[] =
              form.getFieldValue("openplayQueries")?.filter((query) => !!query.id) ?? [];
            const isIn = availableQueries.some((query) => query.id === value);
            if (isIn) {
              return Promise.resolve();
            }
            return Promise.reject("Selected query is not in the list of report queries");
          },
        }),
      ]}
    >
      <OpenplayQueryPicker disabled={isDisabled} />
    </Field>
  );
};

export const ReportSpecificFields = () => {
  const { getAbsolutePath } = useFieldPath();
  const reportQueries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const selectedQueryId = Form.useWatch<string>(getAbsolutePath("query")) ?? null;

  const sourceObject =
    (selectedQueryId && reportQueries.find((query) => query.id === selectedQueryId)?.from) ?? null;

  const openplayEquivalent = getOpenPlayEquivalent(sourceObject);

  const tabSource = Form.useWatch<TabSource>(getAbsolutePath("source")) ?? TabSource.Salesforce;

  return (
    <>
      <Col span={12}>
        <TabSourceField />
      </Col>
      {(tabSource === TabSource.Salesforce || tabSource === TabSource.OpenPlay) && (
        <Col span={12}>
          <QueryPickerField onlyWithOpenplayEquivalents={tabSource === TabSource.OpenPlay} />
        </Col>
      )}
      {tabSource === TabSource.OpenPlay && sourceObject && (
        <Col span={12}>
          <RowByField openplayObject={openplayEquivalent} />
        </Col>
      )}
      {tabSource === TabSource.OpenPlayQuery && (
        <Col span={12}>
          <OpenplayQueryPickerField />
        </Col>
      )}
    </>
  );
};

export const AlertSpecificFields = () => {
  const { getAbsolutePath } = useFieldPath();
  const sourceObjectName = Form.useWatch<string>(["sourceObject", "name"]) ?? null;
  const openplayEquivalent = getOpenPlayEquivalent(sourceObjectName);
  const tabSource = Form.useWatch<TabSource>(getAbsolutePath("source")) ?? TabSource.Salesforce;
  return (
    <>
      {!!openplayEquivalent ? (
        <>
          <Col span={12}>
            <TabSourceField />
          </Col>
          {tabSource === TabSource.OpenPlay && (
            <Col span={12}>
              <RowByField openplayObject={openplayEquivalent} />
            </Col>
          )}
        </>
      ) : (
        <HiddenField name="source" initialValue={TabSource.Salesforce} />
      )}
    </>
  );
};

const OnDemandReportSpecificFields = () => {
  return (
    <>
      <Col span={12}>
        <OpenplayQueryPickerField />
      </Col>
      <HiddenField name="source" initialValue={TabSource.OpenPlayQuery} />
    </>
  );
};

export const SpreadsheetTab = ({ disabled }) => {
  const { localPath, getAbsolutePath } = useFieldPath();
  const fieldIndex = localPath[0];

  const tabSource = Form.useWatch<TabSource>(getAbsolutePath("source")) ?? TabSource.Salesforce;
  const sourceObject = useSourceObject();

  const { entity } = useSpreadsheetFormContext();

  return (
    <Box readOnly={disabled}>
      <Field hidden name="id">
        <Input />
      </Field>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                transform: (value) => value?.trim(),
                message: "Field is mandatory",
              },
              getFieldMaxLengthRule("Name", 30),
              (form) => ({
                validator: (_, value) => {
                  const otherTabNames = form
                    .getFieldValue(["spreadsheet", "tabs"])
                    .filter((_, index) => fieldIndex !== index)
                    .map((tab) => tab.name);
                  return otherTabNames.includes(value)
                    ? Promise.reject("A tab with entered name already exists")
                    : Promise.resolve();
                },
              }),
            ]}
            validateFirst
          >
            <Input placeholder="Tab Name" />
          </Field>
        </Col>
        {entity === "report" && <ReportSpecificFields />}
        {entity === "email-alert" && <AlertSpecificFields />}
        {entity === "on-demand-report" && <OnDemandReportSpecificFields />}
      </Row>
      <Divider orientation="left">Columns</Divider>
      <ColumnSetForm sourceObject={sourceObject} source={tabSource} />
      {entity !== "on-demand-report" && (
        <>
          <Divider orientation="left">Formatting Rules</Divider>
          <FormattingRuleList sourceObject={sourceObject} />
        </>
      )}
    </Box>
  );
};
