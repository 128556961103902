import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { EditableTabs } from "@/components/common/EditableTabs";
import type { ComponentProps } from "react";
import { useFormErrors } from "@/components/common/Form/hooks";
import { SalesforceQueryListItem } from "@/components/Reports/Details/SalesforceQueryList/SalesforceQueryListItem";
import { Alert, Col, Form, Row } from "antd";
import { nanoid } from "nanoid";
import { truncate } from "@/utils";
import type { SpreadsheetTab } from "@/api/spreadsheets/types";
import type { ReportAction, SalesforceQuery } from "@/api/reports/types";

type TabNameProps = {
  tabIndex: number;
};

const TabName = ({ tabIndex }: TabNameProps) => {
  const name = Form.useWatch(["queries", tabIndex, "name"]) ?? null;
  return name ? truncate(name, 15) : `Query #${tabIndex + 1}`;
};

const useNotClosableKeys = () => {
  const tabs = Form.useWatch<SpreadsheetTab[]>(["spreadsheet", "tabs"]) ?? [];
  const actions = Form.useWatch<{
    pre: ReportAction[];
    post: ReportAction[];
  }>(["actions"]);
  const queries = Form.useWatch<SalesforceQuery[]>(["queries"]) ?? [];

  return queries
    .filter((query) => {
      const usedInTabs = tabs.some((tab) => tab.query === query.id);
      const usedInPreActions = actions?.pre?.some((action) => action.queryId === query.id);
      const usedInPostActions = actions?.post?.some((action) => action.queryId === query.id);
      return usedInTabs || usedInPreActions || usedInPostActions;
    })
    .map((query) => queries.indexOf(query));
};

type Props = {
  name: string;
  disabled?: boolean;
  maxQueries?: number;
  rules?: ComponentProps<typeof FieldList>["rules"];
};

export const SalesforceQueryList = ({ name, disabled, rules }: Props) => {
  const { fieldsWithErrors } = useFormErrors();

  const invalidKeys = fieldsWithErrors
    .filter((field) => field.name[0] === "queries")
    .map((field) => field.name[1]);

  const notClosableKeys = useNotClosableKeys();

  return (
    <Row gutter={[16, 16]}>
      {notClosableKeys.length > 0 && (
        <Col span={24}>
          <Alert message="Queries that are used in spreadsheet tabs or actions cannot be removed" />
        </Col>
      )}
      <Col span={24}>
        <FieldList name={name} rules={rules}>
          {(fields, { add, remove }, { errors }) => (
            <div>
              {fields.length > 0 ? (
                <EditableTabs
                  disabled={disabled}
                  invalidKeys={invalidKeys}
                  items={fields.map((field) => ({
                    key: field.name,
                    label: <TabName tabIndex={field.name} />,
                    content: (
                      <FieldListItem key={field.key} name={field.name}>
                        <SalesforceQueryListItem disabled={disabled} />
                      </FieldListItem>
                    ),
                    closable: !notClosableKeys.includes(field.name),
                  }))}
                  onAdd={() => add({ id: nanoid() })}
                  onRemove={(targetKey: number) => {
                    remove(targetKey);
                  }}
                />
              ) : (
                <FullWidthButton
                  label="Add Salesforce Query"
                  icon={Plus}
                  onClick={() => add({ id: nanoid() })}
                />
              )}
              {errors.length > 0 && <Form.ErrorList errors={errors} />}
            </div>
          )}
        </FieldList>
      </Col>
    </Row>
  );
};
