import { api } from "@/api/core";
import type { PaginatedResponse } from "@/api/types";
import { stripUndefined } from "@/utils";
import type {
  CheckQueryResult,
  CreateOpenplayQuery,
  OpenplayQuery,
  OpenplayQueryFilters,
  Schema,
  UpdateOpenplayQuery,
} from "@/api/openplay-queries/types";

export const url = "/api/openplay-queries";

export const fetchOpenplayQuery = (id: string) => api.get<any, OpenplayQuery>(`${url}/${id}`);

export const fetchOpenplayQueries = (params: OpenplayQueryFilters) =>
  api.get<any, PaginatedResponse<OpenplayQuery>>(url, { params });

export const createOpenplayQuery = (fields: CreateOpenplayQuery) => api.post<any, OpenplayQuery>(url, fields);

export const updateOpenplayQuery = (fields: UpdateOpenplayQuery) => api.put<any, OpenplayQuery>(url, fields);

export const deleteOpenplayQuery = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const saveOpenplayQuery = (fields: CreateOpenplayQuery | UpdateOpenplayQuery) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updateOpenplayQuery(cleanedUpFields)
    : createOpenplayQuery(cleanedUpFields);
};

export const findOpenplayQueryByName = async (name: string) => {
  const { data: entries } = await fetchOpenplayQueries({ name });
  return entries.at(0) ?? null;
};

export const checkOpenplayQuery = (sql: string) => {
  if (!sql?.trim()) {
    return null;
  }
  return api.post<any, CheckQueryResult>(`${url}/check`, { sql });
};

export const fetchDatabaseSchema = () => api.get<any, Schema>(`${url}/schema`);
